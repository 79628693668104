import { EInsufficientFunds, type Game } from "@/types";

const useGameModal = () => {
	const isGuest = useIsGuest();
	const { select } = useGamesState();
	const { open, close } = useNlcModals();
	const { handleOpenGame } = useOpenGame(open);
	const { sendAdTrigger } = usePopupAds();

	const route = useRoute();
	const { isSweepStakes } = useSwitchMode();
	const { showPwaModalAfterGame } = usePWA();
	const { openDefault: openPresetPackage } = usePresetPackageController();

	const { data: game, execute: getGame } = useAsyncFetch({
		path: "/rest/game/{id}{mode}/",
		method: "get",
		fetchOptions: {
			path: { id: (route.query?.game as string) || "", mode: !isGuest.value && route.query?.isMoney ? "/play" : "/" }
		},
		options: {
			transform: (data) => data?.[0],
			immediate: false
		}
	});

	const { data: allGamesResponse } = useGetGameFiltersData();
	const allGames = computed(() => select(allGamesResponse.value?.games || []));
	const allGamesSorted = computed(() => {
		const arr = allGames.value?.sort((a: Game, b: Game) => a.id - b.id);
		return arr;
	});
	const currentGameIndex = computed(() => allGamesSorted.value?.findIndex((g: Game) => g.id === game.value?.game?.id));

	const { data: appInit } = useAppInitData();

	const { winnings } = useBalance();

	const hasPromoOffer = computed(() => !!appInit.value?.bannerPresetPackage);

	const insufficientFundsModalType = computed(() => {
		if (!isSweepStakes.value) {
			return hasPromoOffer.value ? EInsufficientFunds.TOURNAMENT_WITH_OFFER : EInsufficientFunds.NO_OFFER;
		}

		if (winnings.value) {
			return EInsufficientFunds.SWEEPSTAKES_WITH_WINNINGS;
		}

		return hasPromoOffer.value ? EInsufficientFunds.SWEEPSTAKES_WITH_OFFER : EInsufficientFunds.NO_OFFER;
	});

	const openInsufficientFundsModal = () => {
		const transactions = window?.$cash?.$store?.getters?.["history/transactions"] as Array<Record<string, string>>;
		const transactionOut = transactions.find((t) => t?.type === "out" && t?.status === "pending");

		if (transactionOut && winnings.value < 1 && isSweepStakes.value) {
			open("LazyOModalInsufficientWithdrawal", {
				amount: numberFormat(+transactionOut.amount),
				id: +transactionOut.id
			});
		} else {
			open("LazyOModalInsufficientFunds", { modalType: insufficientFundsModalType.value });
		}

		dispatchGAEvent({
			type: isSweepStakes.value ? "winnings" : "coins",
			event: "open_popup",
			formName: "empty_balance",
			location: "gameplay"
		});
	};
	const openCashModal = () => {
		if (winnings.value < 1) {
			window?.$cash?.$router?.push?.("/cash/deposit-by-money/");
		} else {
			window?.$cash?.$router?.push?.("/cash/deposit/");
		}

		openPresetPackage();
	};

	const closeModal = async () => {
		if (route.query.game) {
			sendAdTrigger("closeGame");
		}

		const url = route.path.endsWith("/") ? route.path : `${route.path}/`;
		await navigateTo(url, { replace: true });

		if (showPwaModalAfterGame.value) {
			open("LazyOModalPwaApps");
		}

		close("LazyOModalGame");
	};

	const registerGameMessages = (event: MessageEvent) => {
		if (event.data.cash?.popups?.show) {
			openInsufficientFundsModal();
		}

		if (event.data.cash?.popups?.create) {
			const eventSource = event.source as typeof window.parent;
			eventSource.postMessage({ cash: { popups: { created: true } } }, "*");
		}

		if (event.data.cash?.open) {
			openCashModal();
		}

		if (event.data.game?.close) {
			closeModal();
		}
	};

	const switchGame = async (type: "previous" | "next") => {
		dispatchGAEvent({
			event: "click_button",
			button_name: type,
			location: "gameplay"
		});

		let newIndex = currentGameIndex.value;

		if (type === "previous") {
			newIndex = currentGameIndex.value === 0 ? allGamesSorted.value.length - 1 : currentGameIndex.value - 1;
		} else if (type === "next") {
			newIndex = currentGameIndex.value === allGamesSorted.value.length - 1 ? 0 : currentGameIndex.value + 1;
		}

		await closeModal();
		handleOpenGame(allGamesSorted.value[newIndex]?.slug);
	};

	watch(
		() => route.query.game,
		(value, oldValue) => {
			if (oldValue && value === undefined) {
				closeModal();
			}
		}
	);

	const isUserInGame = computed(() => route.query?.game);

	return {
		currentGameIndex,
		game,
		isUserInGame,
		getGame,
		closeModal,
		openCashModal,
		registerGameMessages,
		handleOpenGame,
		switchGame
	};
};
export default useGameModal;
